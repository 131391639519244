import React from 'react'
import Layout from '../../../templates/Main'
import Feed from '../../../modules/Feed'
import Heading from '../../../modules/Heading'
import UploadModal from '../../../modules/UploadModal'
import { connect } from 'react-redux'

const GalleryPage = ({ params, bigText }) => {
    return (
        <Layout id={params.id}>
            <Heading size='h1' text='Gallery' bigText={bigText} />
            <Feed type='gallery' />
            <UploadModal />
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        bigText: state.Vehicle.bigText,
    };
}

export default connect(mapStateToProps)(GalleryPage)
